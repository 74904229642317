.default {
  @mixin button;
}

.secondary {
  @mixin button-secondary;
}

.white {
  @mixin button-white;
}

.link {
  @mixin button-link;
}

.transparent {
  @mixin button-transparent;
}

.mini {
  @mixin button-mini;
}

._mini {
  @mixin button__mini;
}

._tiny {
  @mixin button__tiny;
}

.tiny {
  @mixin button-tiny;
}

._right {
  float: right;
}

.full_width_form {
  width: 100%;
}

.full_width {
  @mixin button;
  @mixin button__fullWidth;
}

/* Classes beginning with an underscore are additive. */
._quiet {
  @mixin button__quiet;
}

.formDefault {
  display: inline-block;
}
